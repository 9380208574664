import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
  })
};

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit {
  @Input() formDark;
  public form: NgForm;
  public name: string;
  public email: string;
  public message: string;
  public mouseOverSubmit: boolean;
  public submitTrigger: boolean = undefined;

  constructor(private http: HttpClient) {
    // this.getProjectPreviews();
  }

  ngOnInit() {
    console.log(this.form);
  }

  //Example JSON
  /**
  {
  "name": "Michael G Fish",
  "email": "mfish0005@gmail.com",
  "message": "Hey human.  I have work for your 10 programmers.  Be prepared for passive income!"
  }
  */
 // postContactForm() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //     })

  //   }
  //   const postUrl = 'https://www.grayfish.io/api/ContactForm/post';

  //   let contactForm = {
  //     id: "1",
  //     name: "Bob Johnson",
  //     email: "bob@bob.com",
  //     message: "Hi there your api works you're rich now"
  //   }

  //   this.http.post(postUrl, contactForm, httpOptions).subscribe(res => {
  //     //Check if response is valid
  //     // if(res['is_valid'] === true) {
  //     //   console.log("Form submitted successfully");
  //     //   //Trigger was a success
  //     //   this.submitTrigger = true;
  //     // } else {
  //     //   console.log("Failed to submit form");
  //     //   //Trigger was a fail
  //     //   this.submitTrigger = false;
  //     // }
  //     console.log(res);
  //   });
  // }
  // getProjectPreviews() {
  //   const url = "http://api.grayfish.io/projectpreviews";
  //   let previews = this.http.get(url).subscribe(res => {
  //     console.log("res: ", res);
  //   });
  //   console.log("previews: ", previews);
  // }

  submit(contactForm: NgForm) {
    // TODO // plug in actual url once server is running
    // const postUrl = 'http://localhost:61652/contacts/post';
    const postUrl = 'https://api.grayfish.io/contacts/post';
    console.log("Submitting contact form data:", contactForm);
    this.http.post(postUrl, contactForm, httpOptions).subscribe(res => {
      //Check if response is valid
      if(res['name'] !== "") {
        console.log("Form submitted successfully");
        //Trigger was a success
        this.submitTrigger = true;
      } else {
        console.log("Failed to submit form");
        //Trigger was a fail
        this.submitTrigger = false;
      }
      console.log("api responded with: ", res);
    });
  }

  // submit(contactForm: NgForm) {
  //   console.log(contactForm);
  //   this.submitTrigger = true;
  // }
}
