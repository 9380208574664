import { Component, OnInit, Input } from '@angular/core';

@Component({
selector: 'app-project-layout',
templateUrl: './project-layout.component.html',
// animations: animationDeclarations
})

export class ProjectLayoutComponent implements OnInit {


  constructor() {
  }

  ngOnInit() {

  }

//   prepareRouteTransition(outlet) {
//     const animation = outlet.activatedRouteData['animation'] || {};
//     return animation['value'] || null;
//   }
}