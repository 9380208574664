import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tour-toyota',
  templateUrl: './tour-toyota.component.html',
  styleUrls: ['./tour-toyota.component.scss']
})
export class TourToyotaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
