import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sandy-bay',
  templateUrl: './sandy-bay.component.html',
})
export class SandyBayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
