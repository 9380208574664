import {Component, OnInit} from '@angular/core';
import {ProjectPreviewModel} from '../models/project-preview.model';
import {GetDataService} from '../services/get-data.service';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
    public projectPreviews: ProjectPreviewModel[];

    constructor(private getData: GetDataService) {
        this.getData.getProjectPreviews().subscribe(projectPreviews => {
            this.projectPreviews = projectPreviews;
        });
    }

    ngOnInit() {
    }
}
