import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

//Third party libraries
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './common/navbar/navbar.component';
import { FooterComponent } from './common/footer/footer.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { ContactFormComponent } from './common/contact-form/contact-form.component';
import { BlogListComponent } from './common/blog-list/blog-list.component';
import { IconListComponent } from './common/icon-list/icon-list.component';
import { TestimonialComponent } from './common/testimonial/testimonial.component';
import { TeamMemberComponent } from './common/team-member/team-member.component';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { MobileNavComponent } from './common/navbar/mobile-nav/mobile-nav.component';

//Project components
import { ProjectComponent } from './project/project.component';
import { ProjectLayoutComponent } from './projects/project-layout-component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectPreviewComponent } from './project-list/project-preview/project-preview.component';
import { TourToyotaComponent } from './projects/tour-toyota/tour-toyota.component';
import { WildcatCostCalculatorComponent } from './projects/wildcat-cost-calculator/wildcat-cost-calculator.component';
import { EagleRareComponent } from './projects/eagle-rare/eagle-rare.component';
import { GeorgetownAdmissionsComponent } from './projects/georgetown-admissions/georgetown-admissions.component';
import { ParkerAgencyComponent } from './projects/parker-agency/parker-agency.component';
import { SandyBayComponent } from './projects/sandy-bay/sandy-bay.component';
import { EkuAdmissionsComponent } from './projects/eku-admissions/eku-admissions.component';
import { SelectASignComponent } from './projects/select-a-sign/select-a-sign.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectListComponent,
    NavbarComponent,
    ProjectPreviewComponent,
    FooterComponent,
    AboutComponent,
    BlogComponent,
    ContactComponent,
    ContactFormComponent,
    BlogListComponent,
    IconListComponent,
    TestimonialComponent,
    TeamMemberComponent,
    BlogPostComponent,
    MobileNavComponent,
    TourToyotaComponent,
    WildcatCostCalculatorComponent,
    EagleRareComponent,
    GeorgetownAdmissionsComponent,
    ParkerAgencyComponent,
    SandyBayComponent,
    EkuAdmissionsComponent,
    SelectASignComponent,
    ProjectLayoutComponent,
    ProjectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
