import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {BlogComponent} from './blog/blog.component';
import {ContactComponent} from './contact/contact.component';
import {BlogPostComponent} from './blog-post/blog-post.component';


//Project components
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectLayoutComponent } from './projects/project-layout-component';
import { TourToyotaComponent } from './projects/tour-toyota/tour-toyota.component';
import { WildcatCostCalculatorComponent } from './projects/wildcat-cost-calculator/wildcat-cost-calculator.component';
import { EagleRareComponent } from './projects/eagle-rare/eagle-rare.component';
import { GeorgetownAdmissionsComponent } from './projects/georgetown-admissions/georgetown-admissions.component';
import { ParkerAgencyComponent } from './projects/parker-agency/parker-agency.component';
import { SandyBayComponent } from './projects/sandy-bay/sandy-bay.component';
import { EkuAdmissionsComponent } from './projects/eku-admissions/eku-admissions.component';
import { SelectASignComponent } from './projects/select-a-sign/select-a-sign.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, data: {animation: 'home'}},
  {path: 'about', component: AboutComponent, data: {animation: 'about'}},
  {path: 'blog', component: BlogComponent, data: {animation: 'blog'}},
  {path: 'post', component: BlogPostComponent, data: {animation: 'blog-post'}},
  {path: 'contact', component: ContactComponent, data: {animation: 'contact'}},
  // {path: 'projects', component: ProjectListComponent, data: {animation: 'projects'}},
  // {path: 'project/:id', component: ProjectComponent, data: {animation: 'project'}},
  {path: 'project-list', component: ProjectListComponent, data: {animation: 'projects'}},
  { path: 'projects', component: ProjectLayoutComponent,
    children: [
      { path: 'tour-toyota', component: TourToyotaComponent },
      { path: 'wildcat-cost-calculator', component: WildcatCostCalculatorComponent },
      { path: 'eagle-rare', component: EagleRareComponent },
      { path: 'georgetown-admissions', component: GeorgetownAdmissionsComponent },
      { path: 'the-parker-agency', component: ParkerAgencyComponent },
      { path: 'sandy-bay', component: SandyBayComponent },
      { path: 'eku-admissions', component: EkuAdmissionsComponent },
      { path: 'select-a-sign', component: SelectASignComponent },
      { path: '', redirectTo: '/project-list', pathMatch: 'full' },
    ]
  },
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
