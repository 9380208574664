import {Component, OnInit, Input} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faGithub } from '@fortawesome/free-brands-svg-icons';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {faFacebook} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() light;
  public faInstagram = faInstagram;
  public faGithub = faGithub;
  public faLinkedinIn = faLinkedinIn;
  public faFacebook = faFacebook;
  public isHome: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        events.url === '/home' || events.url === '/' || events.url === '/contact' ? this.isHome = true : this.isHome = false;
      }
    });
  }

  ngOnInit() {
  }

}
