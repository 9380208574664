import {Component} from '@angular/core';
import {slideInAnimation} from './animations';
import {RouterOutlet} from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [
        slideInAnimation
        // animation triggers go here
    ]
})

export class AppComponent {

    constructor( private meta: Meta) {
        this.meta.addTag({name: 'description', content: 'Grayfish is a software development company based in Eugene, OR.  We build websites, web applications, mobile apps, desktop apps, & APIs.  Our clients include colleges, small businesses, and Fortune 50 companies.'}, true);
        // this.meta.addTag({name: 'keywords', content: 'Grayfish,Gray Fish,Michael Fish,Software Development,Web Development,Web Design,Programmers,Web Developer,Mobile Developer,Website Programmer,App Developer,Web Designers,Mobile Developers,App Developers,Web Developers,Website Developers,Website Programmers,Mobile Development,Grayfish Software,Angular,Software Contractors,Software Contractor,Website Contractor,Website Contractors,Programmer,Eugene Website Developers,Springfield Website Developers,Oregon Website Developers,Eugene Website Designers,Springfield Website Designers,Oregon Website Designers'}, true);
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
