import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wildcat-cost-calculator',
  templateUrl: './wildcat-cost-calculator.component.html',
})
export class WildcatCostCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
