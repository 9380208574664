import {Component, OnInit, ViewChild} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';

//Libraries
import * as Rellax from 'rellax/rellax';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faNode } from '@fortawesome/free-brands-svg-icons';
import { faNpm } from '@fortawesome/free-brands-svg-icons';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCss3 } from '@fortawesome/free-brands-svg-icons';
import { faSass } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faAngular } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faDocker } from '@fortawesome/free-brands-svg-icons';
import { faGit } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faVuejs } from '@fortawesome/free-brands-svg-icons';
import { faYarn } from '@fortawesome/free-brands-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import { faUbuntu } from '@fortawesome/free-brands-svg-icons';
import TypeIt from 'typeit';
import { bounce } from 'ng-animate';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger('bounce', [transition('* => *', useAnimation(bounce))])
    ],
})

export class HomeComponent implements OnInit {
    public icons = [];
    bounce: any;

    constructor() {
    }

    ngOnInit() {
        this.icons.push(
            faHtml5,
            faCss3,
            faSass,
            faBootstrap,
            faJs,
            faNode,
            faNpm,
            faYarn,
            faAngular,
            faReact,
            faVuejs,
            faDocker,
            faGit,
            faGithub,
            faAws,
            faUbuntu
        );
        (window as any).rellax = new Rellax('.rellax');
        // new TypeIt('.type-effect', {
        //     strings: ["the show<i class=\"text-success\">.</i>",'dickballs <i class=\"text-success\">.</i>'],
        //     }).go();
        new TypeIt('.type-effect', {
            speed: 50
          })
          .pause(1300)
          .type('We set the stage to captivate your audience<i class=\"text-success pl-1\">.</i>')
          .go();
    }
}
