import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-project-preview',
    templateUrl: './project-preview.component.html',
    styleUrls: ['./project-preview.component.scss']
})
export class ProjectPreviewComponent implements OnInit {

    @Input() projectPreview;

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    goToProject(projectId) {
        //TODO // Temporary workaround.  Don't have time to do the API or route by id.  Come back to this once we have work rolling in
        console.log(projectId);
        switch(projectId) {
            case 1: this.router.navigate(['/projects/tour-toyota']); break;
            case 2: this.router.navigate(['/projects/wildcat-cost-calculator']); break;
            case 3: this.router.navigate(['/projects/eagle-rare']); break;
            case 4: this.router.navigate(['/projects/eku-admissions']); break;
            case 5: this.router.navigate(['/projects/the-parker-agency']); break;
            case 6: this.router.navigate(['/projects/sandy-bay']); break;
            case 7: this.router.navigate(['/projects/georgetown-admissions']); break;
            case 8: this.router.navigate(['/projects/select-a-sign']); break;
            case 9: this.router.navigate(['/projects/ky-tanks']); break;
        }
    }
}
